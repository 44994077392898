<script setup lang="ts">

</script>

<template>
  <div id="footer" name="footer" class="text-gray-600 pt-8">
    <div class="top  flex justify-center">
      <div class="lg:container lg:px-12 pb-6 text-center">
        <div class="flex flex-col lg:flex-row items-center justify-center">
          <div class="relative z-10 bg-white lg:-mr-8 py-1">
            <LogosPlaya class="w-24 h-12 text-gray-600" />
          </div>
          <div
            class="grid grid-cols-3 gap-4 lg:flex items-center justify-center space-y-8 lg:space-y-0 lg:space-x-4 p-4 lg:pl-12 lg:border border-gray-600"
          >
            <LogosHyattZivaZilara class="w-24 h-12 text-gray-600" />
            <LogosHilton class="w-24 h-12 text-gray-600" />
            <LogosWyndham class="w-24 h-12 text-gray-600" />
            <LogosJewel class="w-24 h-12 text-gray-600" />
            <LogosMarriotBonvoy class="w-24 h-12 text-gray-600" />
            <LogosTapestryCollection class="w-24 h-12 text-gray-600" />
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col items-center p-4 text-sm lg:text-xs">
      <div>
        <ul class="flex flex-col space-y-1 md:space-y-0 md:flex-row items-center list-reset">
          <li class="mx-2">
            <a
              class="text-grey-darker"
              href="https://playaresorts.com/"
            >Playaresorts.com</a>
          </li>
          <li class="mx-2">
            <a class="text-grey-darker" href="/contact">Contact Us</a>
          </li>
          <li class="mx-2">
            <a
              class="text-grey-darker"
              href="https://www.playaresorts.com/terms-conditions"
              target="_blank"
            >{{ $t('terms-and-conditions') }}</a>
          </li>
          <li class="mx-2">
            <a
              class="text-grey-darker"
              href="https://www.playaresorts.com/privacy-policy" target="_blank"
            >Privacy Policy</a>
          </li>
        </ul>
      </div>
      <div class="text-center mt-8 text-xs text-gray-400">
        &copy; {{ new Date().getFullYear() }} Playa Management USA, LLC All rights reserved
      </div>
    </div>
  </div>
</template>
