<script setup lang="ts">
import { usePageStore } from '@voix/store/pageStore'

const props = defineProps({
  menu: {
    type: Object,
    required: true,
  },
})

const mbrData = inject<any>('mbrData')

const menuOpen = ref(false)
const openLanguageSelect = ref(false)

function toggleMenu(item: any) {
  item.open = !item.open
}

function goToLink({ url }) {
  window.open(url, '_blank')
}

const currentPage = computed(() => {
  const pageStore = usePageStore()
  return pageStore.currentPage
})

const showBookingModal = ref(false)

function logout() {
  const mbrCookie = useCookie('mbrAgentData')
  mbrCookie.value = null
  navigateTo('/')
}
</script>

<template>
  <div class="flex items-stretch">
    <div
      class="flex-none max-w-full relative z-10 shadow-2xl bg-abs-white transition-all duration-500 ease-out overflow-hidden"
      :class="{ 'w-0': !menuOpen, 'w-64': menuOpen }"
    >
      <div class="w-64 overflow-hidden">
        <div
          class="bg-[#8ec6e9] text-white uppercase font-bold py-2 px-4 text-center text-xs"
        >
          Travel Professional Portal
        </div>
        <div>
          <button
            class="w-full bg-[#e17a2a] text-white py-3 text-center uppercase font-bold"
            @click="showBookingModal = !showBookingModal"
          >
            Book Now
          </button>
        </div>
      </div>
      <div class="py-8 px-8 text-xs w-64">
        <ul class="list-none border-b pb-2">
          <li
            v-for="item in props.menu.menu_items"
            :key="item.id"
            class="mb-4"
          >
            <div class="flex items-center justify-between space-x-1">
              <a
                :href="item.permalink"
                :target="item.target"
                class="text-gray-600 text-sm leading-6"
              >
                {{ item.name }}
              </a>
              <button
                v-if="item.children && item.children.length > 0"
                class="-mt-2"
                style="width: 12px; height: 12px"
                @click="toggleMenu(item)"
              >
                <Icon
                  name="heroicons:chevron-down-20-solid"
                  class="inline w-6 h-6 text-gray-800"
                />
              </button>
              <div v-else style="width: 12px; height: 12px">
                                &nbsp;
              </div>
            </div>

            <ul
              v-if="item.children && item.children.length > 0"
              class="ml-4 overflow-hidden transition-all duration-500 ease-out"
              :class="{
                'max-h-0 opacity-0': !item.open,
                'max-h-[1000px] opacity-1': item.open,
              }"
            >
              <li v-for="child in item.children" :key="child.id">
                <a
                  :href="child.permalink"
                  :target="child.target"
                  class="text-gray-600 text-sm leading-6 pl-2"
                >
                  {{ child.name }}
                </a>

                <ul
                  v-if="
                    item.children
                      && child.children.length > 0
                  "
                  class="ml-4 pb-2 overflow-hidden transition-all duration-500 ease-out"
                  :class="{
                    'max-h-0 opacity-0': !item.open,
                    'max-h-[500px] opacity-1': item.open,
                  }"
                >
                  <li
                    v-for="gchild in child.children"
                    :key="gchild.id"
                  >
                    <a
                      :href="gchild.permalink"
                      :target="gchild.target"
                      class="text-gray-600 text-sm leading-6 pl-2"
                    >
                      {{ gchild.name }}
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </div>

      <div class="flex flex-col space-y-2 w-64 overflow-hidden">
        <button
          class="relative uppercase font-thin text-white"
          @click="
            goToLink({
              url: 'https://learningcenter.playaresorts.com/learn',
            })
          "
        >
          <div class="relative w-full">
            <img
              src="https://playa-cms-assets.s3.amazonaws.com/media/Agents_Portal/2023/navigation/courses.jpeg"
              alt="Courses"
              class="w-full object-cover"
            >
          </div>
        </button>
        <button
          class="relative uppercase font-thin text-white"
          @click="goToLink({ url: '/fact-sheets' })"
        >
          <div
            class="absolute z-10 top-0 left-0 right-0 p-3 text-center"
          >
            <div class="border-b border-white/25">
              Fact Sheets
            </div>
          </div>
          <div class="relative w-full">
            <img
              src="https://playa-cms-assets.s3.amazonaws.com/media/Agents_Portal/2023/navigation/fact-sheets-agentcash.jpeg"
              alt="Fact Sheets"
              class="w-full object-cover"
            >
          </div>
        </button>
      </div>
    </div>

    <div class="relative z-0 flex-1 bg-[#012343] text-white">
      <div class="text-sm py-2 px-4">
        <div class="flex items-center justify-between">
          <div
            class="w-2/3 lg:w-1/2 flex justify-between"
            @click="menuOpen = !menuOpen"
          >
            <button>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="3"
                stroke="currentColor"
                class="w-7 h-7"
              >
                <path
                  stroke-linecap="square"
                  stroke-linejoin="square"
                  d="M3.75 6.75h16.5M3.75 12h10.5m-10.5 5.25h14.5"
                />
              </svg>
            </button>
            <div>
              <LogosAgentCashPlus class="h-14" />
            </div>
          </div>
          <div class="flex items-center space-x-6">
            <span class="hidden md:inline">
              Welcome
              <strong>{{ mbrData?.FirstName }}</strong></span>
            <span class="hidden md:inline">
              IATA
              <strong>{{ mbrData?.IATANumber }}</strong></span>
            <div
              v-if="currentPage.localizations.length > 1"
              class="hidden md:inline cursor-pointer uppercase text-sm"
            >
              <span
                class="cursor-pointer"
                @blur="openLanguageSelect = false"
                @click.prevent="
                  openLanguageSelect = !openLanguageSelect
                "
              >
                {{ currentPage.language_code }}
                <Icon
                  name="heroicons:chevron-down-20-solid"
                  class="inline w-6 h-6 text-gray-800"
                />
              </span>
              <div
                v-if="openLanguageSelect"
                class="absolute top-0 right-0 py-z bg-grey-lightest z-20 mt-12 mr-2"
              >
                <NuxtLink
                  v-for="localization in currentPage.localizations"
                  :key="localization.path"
                  class="block px-3 py-2 small dvs-cursor-pointer text-xs text-gray-500"
                  :to="localization.path"
                >
                  {{ $t(localization.language_code) }}
                </NuxtLink>
              </div>
            </div>

            <a href="javascript:void(0)" @click.prevent="logout">Log out</a>

            <div class="hidden lg:block">
              <button
                class="w-full bg-[#e17a2a] text-white py-3 px-4 text-center uppercase font-bold"
                @click="showBookingModal = !showBookingModal"
              >
                Book Now
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="showBookingModal" class="relative px-8 z-50 flex justify-center inline-flight-booking bg-black">
        <button class="absolute top-0 right-0 text-xs uppercase m-4" @click="showBookingModal = !showBookingModal">
          Close
        </button>
        <FlightBookingForm />
      </div>

      <div class="text-gray-600">
        <slot />
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
